<template>
    <v-col>
        <v-row>
            <v-col md="4" v-for="(icon, x) in icons" :key="x"  v-if="icon.permissions">
                <v-card class="text-center"  >
                    <a :href="icon.url">
                        <v-img 
                            :src="require('@/assets/images/'+icon.icon)"
                            :width=icon.width
                            :height=icon.height
                            style="margin:0 auto"
                        />
                    </a>
                    <b> {{ icon.name }} </b>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'IconsEcommerceWeb',
    data: ()=>({
        icons:[
            {
                icon:'slider-ecommerce.jpg',
                name:'SLIDER PRINCIPAL',
                width:'270px',
                height:'200px',
                url:'slider-home-ecommerce',
                permissions: false
            },
            {
                icon:'contactos.png',
                name:'CONTACTOS',
                width:'200px',
                height:'200px',
                url:'concatos-webcommerce',
                permissions: false
            },
            {
                icon:'productos-ecommerce.jpg',
                name:'PRODUCTOS',
                width:'200px',
                height:'200px',
                url:'productos-webcommerce',
                permissions: false
            }
            ,
            {
                icon:'personal.jpg',
                name:'PERSONAL',
                width:'230px',
                height:'200px',
                url:'personal-webcommerce',
                permissions: false
            }
            ,
            {
                icon:'direcciones.png',
                name:'DIRECCIONES',
                width:'200px',
                height:'200px',
                url:'direcciones-webcommerce',
                permissions: false
            }
        ]
    }),
    computed:{
        ...mapState('access',['idActionsRol'])
    },
    mounted(){

        this.icons[0].permissions = this.idActionsRol.filter(e => e == 'CHANGE_SLIDER_HOME').length
        this.icons[1].permissions = this.idActionsRol.filter(e => e == 'CHANGE_CONTACT_WEB').length
        this.icons[2].permissions = this.idActionsRol.filter(e => e == 'CHANGE_PRODUCTS_WEB').length
        this.icons[3].permissions = this.idActionsRol.filter(e => e == 'CHANGE_PERSONAL_WEB').length
        this.icons[4].permissions = this.idActionsRol.filter(e => e == 'CHANGE_DIRECTION_WEB').length
        //this.icons[4].permissions = idActionsRol.filter(e => e == 'CHANGE_SLIDER_HOME').length
       // this.icons[5].permissions = idActionsRol.filter(e => e == 'CHANGE_SLIDER_HOME').length
    
    }
}

</script>